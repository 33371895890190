import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueIntro from 'vue-introjs'
import 'intro.js/introjs.css';
import { createProvider } from './vue-apollo'
import VueHtml2Canvas from 'vue-html2canvas';
import VueCookies from 'vue-cookies'

// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import config from './config';

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts);
Vue.use(VueIntro, {
  // waitTimeout: 400
});
Vue.use(VueHtml2Canvas);
Vue.use(VueCookies);

Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  vuetify,
  VueIntro,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app');

Vue.prototype.$apiUrl = config.apiUrl;
