<template>
  <v-container fill-height>
    <v-row class="text-center top-offset" justify="center" align="center">
      <!-- <v-col cols="12">
        <cart />
      </v-col> -->
       <v-col cols="12" lg="6" md="6" justify="center" align="center">
        <PieStatus naming="Cards" />
      </v-col>
       <v-col cols="12" lg="6" md="6" justify="center" align="center">
        <Pie naming="Customers" />
      </v-col>

      <v-col cols="12" lg="10" class="offset-lg-1">
        <!-- <DataTable /> -->
      </v-col>

      <!-- <v-col cols="12" lg="8" class="offset-lg-2">
        <AxiosDemo />
      </v-col> -->
     
     <!-- <v-col cols="12" lg="8" class="offset-lg-2">
        <GraphQLDemo />
      </v-col> -->

    </v-row>
  </v-container>
</template>

<script>

// import Cart from '../components/Cart'
// import DataTable from './DataTable.vue'
import Pie from './Pie.vue'
import PieStatus from './PieStatus.vue'
// import GraphQLDemo from './GraphQLDemo.vue'
// import AxiosDemo from './AxiosDemo.vue'

  export default {
    name: 'Dashboard',
    components: {
      // Cart,
      // DataTable,
      Pie,
      PieStatus,
      // GraphQLDemo,
      // AxiosDemo
    },
    props: {
      
    },

    data: () => ({
      
    }),

    created() {
    },

    mounted() {
      // if (localStorage.getItem('auth')) {
      //   try {
      //     this.auth = JSON.parse(localStorage.getItem('auth'));
      //   } catch(e) {
      //     localStorage.removeItem('auth');
      //   }
      // }
        // if (localStorage.name) {
        this.name = localStorage.name;
      // }
    },
  }
</script>

<style scoped>
  .top-offset{
    margin-top: 3rem !important;
  }
</style>