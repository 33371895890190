export function stringifyArray(arr) {
    let stringified = arr
    .reduce((acc, curr, idx, arr) => {
      let ret = acc;
      if (idx === 0) {
        ret += "[";
      }
      ret += `"${curr}"`;
      if (idx === arr.length - 1) {
        ret += "]";
      } else {
        ret += ",";
      }
      return ret;
    }, "");
    if (!stringified) {
      stringified = "[]";
    }
    return stringified;
  }