<template>
  <v-app>
    <MainNavigation v-if="$route.meta.visibleNavbar" />
    <v-main>
      <!-- <transition name="slide-fade"> -->
      <router-view/>
      <!-- </transition> -->
    </v-main>
  </v-app>
</template>

<script>
import MainNavigation from './components/MainNavigation.vue'

export default {
  name: 'App',
  components: {
    MainNavigation
  },
  computed: {
    loggedIn() {
      return this.$store.state.isAuthenticated;
    }
  },
  watch: {
    loggedIn: {
      async handler(success) {
      if (success) {
        await this.$store.dispatch("getCustomers", {
            token: await this.$auth.getAccessToken()
          });
        await this.$store.dispatch("getAvailableGroups", {
            token: await this.$auth.getAccessToken()
          });
        await this.$store.dispatch("getActiveGroups", {
            token: await this.$auth.getAccessToken()
          });
      }
    },
      immediate: true
    }
  },
};
</script>

<style scoped>
  /* v-app{
    background-color:black;
  } */
  #tableContainer {
    max-width: 1400px;
  }
</style>
