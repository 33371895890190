<template>
  <v-container class="login-background" fluid fill-height>
    <v-card class="mt-4 mx-auto text-center border" min-width="320">
      <div v-intro="'The content of tooltip'" v-intro-step="1">
        <!-- v-intro-autostart="true"-->
        <v-card-text>
          <v-card
            class="v-card--offset mx-auto"
            color="primary"
            elevation="4"
            dark
            outlined
          >
            <v-card-text class="headline white--text" max-width="150px">
              <v-row justify="center" align="center">
                <v-col cols="4" md="4">
                  <v-img
                    max-width="150px"
                    :src="require('../assets/calzedonia-logo.png')"
                  ></v-img>
                </v-col>
                <v-col cols="8">
                  <v-badge color="green" content="v0.0.1" right>
                    <h2>Discount Card App</h2>
                  </v-badge>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-form>
          <v-card-text>
             <v-row align="center" no-gutters>
              <v-col class="text-center">
                <div class="my-2">
                    <div v-if="finished && !authenticated" :class="`text-md-body-1`">
                        Not authorized
                    </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios';

import { isAuthError } from '../helpers/apiAuth';

export default {
  name: "Login",
  data: () => ({
      authenticated: false,
      finished: false
  }),
   async created () {
    this.$store.state.applicationDataState.drawer = false;
    await this.apiAuthCheck();
  },
  methods: {
      apiAuthCheck: async function() {
        axios.post(this.$apiUrl, {
            query: `query {me {firstName}}`
          }, {
            headers: {
              Authorization: "Bearer " + await this.$auth.getAccessToken(),
            }
          }).then(() => {   
            this.$router.replace({ name: "Home" });
        }).catch(error => {
            if (isAuthError(error)) {
                this.prototype.$apiAuthenticated = false;
            }
        }).finally(() => { this.finished = true });
      }
  }
};
</script>

<style scoped>
.login-background {
  /* background: url(../assets/backgroundjpg.jpg); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}
/* .login-gradient { */
  /* background-image: linear-gradient(#4E51FB, #4043E1); */
  /* border: 15px solid #4043E1 !important; */
/* } */

.border{
  border: 1px solid #007bff;
}
</style>