import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import config from '../config';
import axios from 'axios';
import { stringifyArray } from '../helpers/util';

const { apiUrl } = config;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated: false,
    applicationDataState:{
      drawer: false,
    },
    cards: [],
    customers: [],
    definitions: [],
    availableGroups: null,
    activeGroups: null
  },
  mutations: {
    setAuthentication(state, status){
      state.isAuthenticated = status;
    },
    setCustomers(state, customers){
      state.customers = customers;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAvailableGroups(state, availableGroups) {
      state.availableGroups = availableGroups
    },
    setActiveGroups(state, activeGroups) {
      state.activeGroups = activeGroups
    },
    addCustomer(state, customer) {
      state.customers.push(customer);
    },
    updateCustomer(state, customer) {
      let idx = -1;
      for (let i = 0; i < state.customers.length; i++) {
        if (state.customers[i].id === customer.id) {
          idx = i;
          break;
        }
      }
      if (-1 !== idx) {
        Vue.set(state.customers, idx, customer);
      }
    },
    setDefinitions(state, definitions) {
      state.definitions = definitions;
    }
  },
  getters: {
    customerCards(state) {
      return function (id) {
        let cards = [];
        const customerIdx = state.customers.findIndex(customer => customer.id === id);
        if (-1 !== customerIdx) {
          cards = state.customers[customerIdx].cards || [];
        }
        return cards
      };
    },
    customersInGroup(state) {
      return function(group) {
        const customers = [];
        for (let i = 0; i < state.customers.length; i++) {
          const customer = state.customers[i];
          if (customer.cards.some(c => c.name === group)) {
            customers.push(customer);
          }
        }
        return customers;
      }
    }
  },
  actions: {
    async getCustomers({ commit }, { token }) {
      const customers = [];
      try {
        const response = await axios
        .post(
          apiUrl,
          {
            query: `query{
							customers{
								id
								firstName
								lastName
								email
								company
								assignedBy{
									email
								}
								cards{
									id
									status
									name
									barcode
                  timeOfLastSentEmail
									discounts{
										id
										brand
										percentage
									}
								}
							}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response.data.errors == undefined) {
          for (var i = 0; i < response.data.data.customers.length; i++) {
            var customer = response.data.data.customers[i];
            for (let j = 0; j < customer.cards.length; j++) {
              const card = customer.cards[j];
            if (card.timeOfLastSentEmail != null) {
                card.timeOfLastSentEmail = new Date(
                  parseInt(card.timeOfLastSentEmail)
                ).toLocaleString();
              }
            }
            customers.push(customer);
          }
          commit(
            "setCustomers",
            customers
          );
        } else {
          throw new Error(response.data.errors[0].message);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async createCustomer({ commit, dispatch }, { token, customer, notifyBrands }) {
      return new Promise((resolve, reject) => {
      let cards = stringifyArray(customer.cards.map(card => card.name));
      axios
        .post(
          apiUrl,
          {
            query: `mutation{
							createCustomer(customerInput: {
								firstName: "${customer.firstName}",
								lastName: "${customer.lastName}",
								email: "${customer.email}",
								company: "${customer.company}",
								cards: ${cards},
                notifyBrands: ${notifyBrands}
							}){
								id
								firstName
								lastName
								email
								fullName
								company
                assignedBy{
									email
								}
								cards{
									id
									status
									name
									barcode
									discounts{
										brand
										percentage
									}
								}
							}
						}`,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(async (response) => {
          if (response.data.errors == undefined) {
            if (cards.length > 0) {
              this.state.availableGroups = null;
              this.state.activeGroups = null;
              await dispatch('getAvailableGroups', { token });
              await dispatch('getActiveGroups', { token });
            }
            commit(
              "addCustomer",
              response.data.data.createCustomer
            );
            
            resolve(true);
          } else {
            reject(response.data.errors[0].message);
          }
        }).catch(err => reject(err));
      });
    },
    async updateCustomer({ commit, dispatch }, { token, customer, notifyBrands }) {
      return new Promise((resolve, reject) => {
        const cards = stringifyArray(customer.cards.map(card => card.name));
        axios
        .post(
          apiUrl,
          {
            query: `mutation{
						updateCustomer(customerUpdateInput: {
							id: "${customer.id}",
							firstName: "${customer.firstName}",
							lastName: "${customer.lastName}",
							email: "${customer.email}",
							company: "${customer.company}",
							cards: ${cards},
              notifyBrands: ${notifyBrands}
						}){
							id
							firstName
							lastName
							email
							fullName
							company
              assignedBy{
                email
              }
							cards{
								id
								status
								name
								barcode
								discounts{
									id
									brand
									percentage
								}
							}
						}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
        .then(async (response) => {
          if (response.data.errors == undefined) {
            this.state.availableGroups = null;
            this.state.activeGroups = null;
            await dispatch('getAvailableGroups', { token });
            await dispatch('getActiveGroups', { token });
            commit(
              "updateCustomer",
              response.data.data.updateCustomer
            );
            resolve(true);
          } else {
            reject(response.data.errors);
          }
        });
      });
    },
    async getAvailableGroups({ state, commit }, { token }) {
      if (state.availableGroups === null) {
        try {
          const response = await axios
          .post(
            apiUrl,
            {
              query: `query{
              groupNames(onlyNew: true) {
                groups {
                  discounts {
                    id
                    percentage
                    brand
                  }
                  name
                  }
                }
              }`,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (response.data.data.groupNames.groups != null) {
            commit(
              "setAvailableGroups",
              response.data.data.groupNames.groups
            );
          }
        } catch (err) {
          console.log(err);
        }
      }
      return state.availableGroups;
    },
    async getActiveGroups({ state, commit }, { token }) {
      if (state.activeGroups === null) {
        try {
          const response = await axios
          .post(
            apiUrl,
            {
              query: `query{
              groupNames(active: true) {
                groups {
                  discounts {
                    id
                    percentage
                    brand
                  }
                  name
                  }
                }
              }`,
            },
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (response.data.data.groupNames.groups != null) {
            commit(
              "setActiveGroups",
              response.data.data.groupNames.groups
            );
          }
        } catch (err) {
          console.log(err);
        }
      }
      return state.activeGroups;
    },
    async getDefinitions({ commit }, { token }) {
      try {
        const response = await axios
        .post(
          apiUrl,
          {
            query: `query{
							definitions{
								definitionKey
							}
					}`,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (response.data.errors == undefined) {
          commit(
            "setDefinitions",
            response.data.data.definitions
          );
        } else {
          throw new Error(response.data.errors[0].message);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  modules: {
  },
  plugins: [createPersistedState({
    paths: ['isAuthenticated'],
    storage: window.sessionStorage,
})],
})
