<template>
  <div v-if="$route.meta.visibleNavbar">
    <v-app-bar flat fixed>
      <v-app-bar-nav-icon
        @click="$store.state.applicationDataState.drawer = !$store.state.applicationDataState.drawer">
      </v-app-bar-nav-icon>
      <v-toolbar-title>{{ currentRouteName }}</v-toolbar-title>
      <v-toolbar-title class="flex text-right">{{ user.welcomeMsg }}<span style="color: #007bff;">{{user.name}}</span></v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      class="navigation-overflow"
      v-model="$store.state.applicationDataState.drawer"
      absolute
      temporary
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="primary--text text--accent-4"
        >
          <v-img          
            :src="require('../assets/calzedonia-group-logo.png')"
            fill-height
          ></v-img>
          <v-list-item to="/home">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item to="/cards-managment">
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cards Managment</v-list-item-title>
          </v-list-item>

          <v-list-item to="/manage-users">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Manage Customers</v-list-item-title>
          </v-list-item>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-credit-card-search</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Bulk Email Send</v-list-item-title>
          </v-list-item>

           <v-list-item to="/discount-card">
            <v-list-item-icon>
              <v-icon>mdi-cards</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cancel Download</v-list-item-title>
          </v-list-item>

          <v-list-item to="/report">
            <v-list-item-icon>
              <v-icon>mdi-file-chart</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Report</v-list-item-title>
          </v-list-item>

          <v-list-item to="/" @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
// import VueCookies from 'vue-cookies'
export default {
  data: () => ({
    group: null,
    user: {
      name: "",
      welcomeMsg: "Welcome, "
    }
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },

  created() {
    // this.drawer = !this.drawer;
    //no-unused-vars
    // this.$router.history.listen((newLocation) => {console.log(newLocation);this.drawer = false;})
    this.user.name = "";
    this.me();
  },
  methods: {
    logout: async function() {
      this.$store.state.applicationDataState.drawer = false;      
      axios
        .post(
          this.$apiUrl,
          {
            query: `mutation {logout(noop: "") { success } }`
          },
          {
            headers: {
              Authorization: "Bearer " + await this.$auth.getAccessToken(),
            }
          }
        )
        .then(response => {
          if (response.data.data.logout.success) {
            this.$auth.logout();
          }
        });
    },
    async me() {
      axios
        .post(
          this.$apiUrl,
          {
            query: `query {me {firstName}}`
          },
          {
            headers: {
              Authorization: "Bearer " + await this.$auth.getAccessToken(),
            }
          }
        )
        .then(response => {
          this.user.name = response.data.data.me.firstName;
        });
    }
  }
};
</script>

<style scoped>
.navigation-overflow {
  position: fixed;
  top: 0px;
  left: 0;
  /* overflow-y: scroll; */
}
</style>