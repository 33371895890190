<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" id="chart">
        <h1>{{ naming }}</h1>
        <apexchart
          type="pie"
          height="380"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="dialog"
          max-width="500"
        >
          <v-card
          class="border"
          >
            <v-card-title class="text-h5">
              Statistics
            </v-card-title>
            <v-card-text>
              Element that you clicked: {{chartDialog}}
            </v-card-text>
            <v-row>
              <v-col cols="12" lg="12" md="12">
                <Bar />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="teal"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import Bar from "../components/Bar.vue";
import axios from "axios";



export default {
  components: {
    Bar
  },
  props: {
    naming: String
  },
  mounted(){
    this.getAvailableCards();
  },
  methods: {
    chartDialogs: function() {
      this.dialog = true;
    },
    async getAvailableCards() {
      console.log(this.$apiUrl);
      axios
        .post(this.$apiUrl, {
          query: `query{
            cardsByStatuses{
              ALL
              NEW
              USED
              INVALID
            }
          }`
        },{

        headers: {
          Authorization: 'Bearer ' + await this.$auth.getAccessToken(),
        }
      })
        .then((response) => {
          let res = response.data.data.cardsByStatuses;
          console.log(Object.getOwnPropertyNames(res));
          this.series.push(
           res["NEW"],
           res["USED"],
           res["INVALID"]
          )
        });
    }
  },
  computed: {
    chartOptions: function() {
      return {
        chart: {
          width: 380,
          type: "pie",
          events: {
            // dataPointSelection: (event, chartContext, config) => {
            //   let sum = 0;
            //   for (let i = 0; i < config.w.globals.series.length; i++) {
            //     sum += config.w.globals.series[i];
            //   }

            //   console.log(
            //     config.w.globals.series[config.dataPointIndex] +
            //       " - " +
            //       config.w.globals.series[config.dataPointIndex] / sum * 100 +
            //       "%"
            //   );

            //   console.log(
            //     config.w.globals.series[config.dataPointIndex] / sum * 100
            //   );
            //   this.chartDialog =
            //     config.w.globals.series[config.dataPointIndex] / sum * 100;

            //   this.chartDialogs();
            // }
            // legendClick: (config) => {
            //   // this.dialog = true;
            //     // console.log(this.dialog);
            //   this.chartDialog = config.w.globals.series[config.dataPointIndex]/(sum)*100;
            //     this.chartDialogs();
            //     console.log("This is madness");

            // }
            // click(event, chartContext, config) {
            //   this.dialog = true;
            //   console.log(this.dialog);
            // console.log(event);
            // console.log(chartContext);
            // console.log(config.globals.series);
            // }
          }
        },
        legend: {
          formatter: function(val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
          labels: {
            useSeriesColors: true,
          },
            position: 'bottom'
        }
        ,
          
        labels: [
          "New",
          "Used",
          "Invalid"
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 320
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };
    }
  },

  data: function() {
    return {
      dialog: false,
      chartDialog: 0,
      series: []
    };
  }
};
</script>

<style scoped>
.border {
  border: 1px solid #009688;
}
</style>
