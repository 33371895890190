<template>
  <Dashboard />
</template>

<script>
  import Dashboard from '../components/Dashboard'

  export default {
    name: 'Home',

    components: {
      Dashboard,
    },
  }
</script>
