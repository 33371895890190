<template>
    <div></div>
</template>
<script>
// extended. original taken from https://github.com/okta/okta-vue/blob/2.1/src/components/ImplicitCallback.vue
import axios from 'axios';
import { isAuthError } from '../helpers/apiAuth';
import api from '../mixins/api';

export default {
  name: 'ImplicitCallback',
  mixins: [api],
  async beforeMount () {
    await this.$auth.handleAuthentication();
    try {
        console.log(this.apiUrl);
        await axios.post(this.apiUrl, {
            query: `query {me {firstName}}`
        }, {
            headers: {
            Authorization: "Bearer " + await this.$auth.getAccessToken(),
        }});
        console.log('EOWEOWEOWOWEOWEOEW');
        this.$store.commit("setAuthentication", true);
            this.$router.replace({
                path: this.$auth.getFromUri()
            });
    } catch (error) {
        if (isAuthError(error)) {
            this.$store.commit("setAuthentication", false);
            this.$router.replace({
                path: '/login'
            });
        }
    }
  }
}
</script>
