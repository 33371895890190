<template>
  <v-container class="login-background" fluid fill-height>
    <!-- Start Error Dialog -->
      <v-dialog v-model="errorDialog" max-width="500px">
      <v-card>
        <v-alert
        type="warning"
        prominent
        border="left"
        dismissable
        close-text="Close Alert"
        class="ma-0"
      >
        <v-row align="center">
          <v-col class="grow">
            {{errorMessage}}
          </v-col>
          <v-col class="shrink">
            <!-- <v-btn>Take action</v-btn> -->
            <v-btn color="white" text @click="errorDialog = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
    <!-- End Error Dialog -->
    <v-card class="mt-4 mx-auto text-center border" min-width="320">
      <div v-intro="'The content of tooltip'" v-intro-step="1">
        <!-- v-intro-autostart="true"-->
        <v-card-text>
          <v-card
            class="v-card--offset mx-auto"
            color="primary"
            elevation="4"
            dark
            outlined
          >
            <v-card-text class="headline white--text" max-width="150px">
              <v-row justify="center" align="center">
                <v-col cols="4" md="4">
                  <v-img
                    max-width="150px"
                    :src="require('../assets/calzedonia-logo.png')"
                  ></v-img>
                </v-col>
                <v-col cols="8">
                  <v-badge color="green" content="v0.0.1" right>
                    <h2>Discount Card App</h2>
                  </v-badge>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      <div id="okta-signin-container"></div>
        <v-form>
          <v-card-text>
             <v-row align="center" no-gutters>
              <v-col class="text-center">
                <div class="my-2">
                  <v-btn
                    x-large
                    color="primary"
                    class="white--text"
                    type="submit"
                    block
                    @click.prevent="login"
                    >Log In with Okta</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </div>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: "Login",
  data: () => ({
    input: {
      username: "",
      password: "",
      hashPass: 0,
    },
    errorDialog: false,
    errorMessage: "",
    showPassword: false
  }),
   async created () {
    this.$store.state.applicationDataState.drawer = false;
  },
  methods: {
    login: function () {
      this.$auth.loginRedirect()
      /*axios.post("http://localhost:3002/login").then(() => {
      });*/
      // this.$store.commit("setAuthentication", true);
      /*axios
      .post(this.$apiUrl, {
        query: `mutation { login (username: "${this.input.username}", password: "${this.input.password}") { loggedIn jwt } }`,
      })
      .then((response) => {
          
        if(response.data.data.login.loggedIn && response.data.errors == undefined) {
          this.$cookies.set("access-token",response.data.data.login.jwt)
          console.log("User is Logged In", response.data);
          this.$store.commit("setAuthentication", response.data.data.login.loggedIn);
          this.$router.replace({ name: "Home" });
          console.log(response.data);
          this.accounts = response.data.data.accounts;
        }    
        else{
          this.errorMessage = "Credentials are not correct!";
          this.errorDialog = true;

        }        
      })
      .catch(function (error) {
        console.log(error);
      });*/
    }
  },
};
</script>

<style scoped>
.login-background {
  /* background: url(../assets/backgroundjpg.jpg); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
}
/* .login-gradient { */
  /* background-image: linear-gradient(#4E51FB, #4043E1); */
  /* border: 15px solid #4043E1 !important; */
/* } */

.border{
  border: 1px solid #007bff;
}
</style>