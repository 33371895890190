import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OktaInitiatedSignIn from '../views/OktaInitiatedSignIn.vue'
import Login from '../views/Login.vue'
import ApiAuthCheck from '../components/ApiAuthCheck.vue'
// import CardsManagment from '../views/CardsManagment.vue'
// import DiscountCard from '../views/DiscountCard.vue'
// import ManageUsers from '../views/ManageUsers.vue'
// import Report from '../views/Report.vue'
// import About from '../views/About.vue'
import OktaVue from '@okta/okta-vue'
import ImplicitCallback from '../components/ImplicitCallback';
import store from '../store';

/*Vue.use(OktaVue, {
  clientId: '0oa7wlwc14YBSNfeh5d7',
  issuer: 'https://dev-37009932.okta.com/oauth2/default',
  discoveryUri: 'https://dev-37009932.okta.com/oauth2/default',
  endSessionRedirectUri: window.location.origin,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  requireHardwareBackedKeyStore: false
});*/
Vue.use(OktaVue, {
  clientId: '0oa42h27l3kAhBHR3697', // 0oa4d5r5yoBhpgWhE697
  issuer: 'https://login.calzedonia.com',
  discoveryUri: 'https://login.calzedonia.com',
  endSessionRedirectUri: window.location.origin,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  requireHardwareBackedKeyStore: false
});

Vue.use(VueRouter);


const routes = [
  {
    path: '/apiAuthCheck',
    component: ApiAuthCheck,
  },
  {
    path: '/oktaInitiatedSignIn',
    component: OktaInitiatedSignIn,
  },
  {
    path: '/login/callback',
    mode: 'history',
    component: ImplicitCallback
  },
  {
    path: '/home',
    name: 'Home',
    alias: '/',
    component: Home,
    meta: { 
      visibleNavbar: true,
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'Bulk Email Send',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
    meta: { 
      visibleNavbar: true,
      requiresAuth: true
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      visibleNavbar: false
    }
  },
  {
    path: '/cards-managment',
    name: 'Cards Managment',
    component: () => import(/* webpackChunkName: "cards-managment" */ '../views/CardsManagment.vue'),
    meta: {
      visibleNavbar: true,
      requiresAuth: true
    }
  },
  {
    path: '/manage-users',
    name: 'Manage Customers',
    component: () => import(/* webpackChunkName: "manage-users" */ '../views/ManageUsers.vue'),
    meta: {
      visibleNavbar: true,
      requiresAuth: true
    }
  },
  {
    path: '/discount-card',
    name: 'Download Canceled Cards',
    component: () => import(/* webpackChunkName: "discount-card" */ '../views/DiscountCard.vue'),
    // component: DiscountCard,
    meta: { 
      visibleNavbar: true,
      requiresAuth: true
    }
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue'),
    meta: { 
      visibleNavbar: true,
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && (!await Vue.prototype.$auth.isAuthenticated() || (await Vue.prototype.$auth.isAuthenticated() && !store.state.isAuthenticated))) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router
